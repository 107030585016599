export default function highlightPlagiarizedText(originalText, plagiarizedTextOffsets) {
    let highlightedText = '';
    let previousOffset = 0;
    console.log('Original text', originalText);
    console.log('Plagiarized text offsets', plagiarizedTextOffsets);
    // Sort the offsets in ascending order
    plagiarizedTextOffsets.sort(function(a, b) {
        return a - b;
    });

    // Iterate through each offset and highlight the corresponding text
    plagiarizedTextOffsets.forEach(function(offset) {
        let [offsetStart, offsetEnd] = offset;
        let textBefore = originalText.substring(previousOffset, offsetStart);
        let plagiarizedText = originalText.substring(offsetStart, offsetEnd);

        // Add the highlighting HTML element around the plagiarized text
        highlightedText += textBefore + '<span style="background-color:yellow">' + plagiarizedText + '</span>';

        // Update the previous offset for the next iteration
        previousOffset = offsetEnd;
    });

    // Add the remaining text after the last offset
    highlightedText += originalText.substring(previousOffset);

    console.log("Highlighted text", highlightedText);
    // return the highlighted text as the content of the target element
    return highlightedText;
}
