import React, {useEffect, useCallback} from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Link from "@mui/material/Link";
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from "@mui/material/CircularProgress";

import io from "socket.io-client";

import NumberCard from "./number_card";
import About from "./about";

import {toCapitalCase} from "../../common/utils/string";
import {deleteCookie, getCookie, storeCookie} from "../../common/utils/localStorage";
import {Helmet} from "react-helmet";
import Alert from "@mui/material/Alert";

const socket = io.connect(process.env.REACT_APP_BACKEND_URL);

const fibonacci = [0, 0.5, 1, 2, 3, 5, 8, 13, 20, 40, 100, "?"];

const theme = createTheme({
    palette: {
        primary: {
            main: '#088395',
            darker: '#05BFDB',
        },
    },
});

const Item = styled(Paper, {
    shouldForwardProp(propName) {
        return propName !== 'background';
    }
})(({theme, background}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: theme.palette.text.secondary,
    background: background,
    height: 50,
    width: '200px',
    lineHeight: '60px',
    marginBottom: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
}));


const ScrumPoker = () => {

    const [joinOrCreateRoom, setJoinOrCreateRoom] = React.useState("create");
    const [room, setRoom] = React.useState("");
    const [name, setName] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [showRoom, setShowRoom] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = React.useState(null);
    const [min, setMin] = React.useState(0);
    const [max, setMax] = React.useState(10000);
    const [showEstimates, setShowEstimates] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("")

    const onClickJoin = () => {
        console.log("Joining room " + room + " as " + name);
        if (room !== "" && name !== "") {
            // socket.emit("join_room", JSON.stringify({room, name}));
            socket.emit("join_room", {room, name});
            storeCookie("room", room)
            storeCookie("name", name)
            setShowRoom(true)
        }
    }

    const onClickCreateRoom = () => {
        console.log("Creating room " + room + " as " + name);
        if (room !== "" && name !== "") {
            console.log("Before emitting create_room")
            socket.emit("create_room", {room, name});
            storeCookie("room", room)
            storeCookie("name", name)
            setShowRoom(true)
        }
    }

    const onClickJoinExistingRoom = (e) => {
        setJoinOrCreateRoom("join");
    }

    const onClickCreateNewRoom = (e) => {
        setJoinOrCreateRoom("create");
    }

    const onChangeRoom = (event) => {
        setRoom(event.target.value);
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onClickShowEstimates = (event) => {
        // console.log("Showing estimates")
        socket.emit("show_estimates", room);
    }

    const onClickHideEstimates = (event) => {
        socket.emit("hide_estimates", room);
    }

    const onClickDeleteEstimates = (event) => {
        // console.log("Deleting estimates")
        socket.emit("delete_estimates", JSON.stringify({room, users}));
    }

    const onClickLeaveRoom = () => {
        socket.emit("leave_room", JSON.stringify({room, name, users}));
        setName("")
        setRoom("")
        setShowRoom(false)
        setShowEstimates(false)
        deleteCookie("room")
        deleteCookie("name")
    }

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    useEffect(() => {
        const isCookiePresent = getCookie("room") && getCookie("name")
        const isUserInRoom = name !== "" && room !== ""
        if (isCookiePresent && !isUserInRoom) {
            const room = getCookie("room")
            const name = getCookie("name")
            socket.emit("join_room", JSON.stringify({room, name}));
            setName(name)
            setRoom(room)
            setShowRoom(true)
        }
    })

    useEffect(() => {
        console.log("on getting socket data in useEffect")

        socket.on('error', ({ message }) => {
            console.log("Error from server", message)
            setShowRoom(false)
            setOpenSnackBar(true)
            setSnackbarMessage(message)
            return
        })

        socket.on('roomData', ({room, users}) => {
            console.log("roomData", room, users)
            setUsers(users);
            // setRoom(room);
        })

        socket.on('selected_number', (users) => {
            console.log("on selected_number event ===>>> ", users)
            setUsers(users);
            const min = users.reduce((min, user) => user.selectedNumber < min ? user.selectedNumber : min, 10000);
            const max = users.reduce((max, user) => user.selectedNumber > max ? user.selectedNumber : max, 0);
            setMin(min);
            setMax(max);
        })

        socket.on('show_estimates', (dummy) => {

            console.log("Showing estimates from useEffect")
            setShowEstimates(true);
        })

        socket.on('hide_estimates', (dummy) => {
            console.log("Hiding estimates from useEffect")
            setShowEstimates(false);
        })

        socket.on('delete_estimates', (users) => {
            console.log("Deleting estimates from useEffect", users)
            users.map((user) => {
                delete user.selectedNumber
                return user
            })
            setUsers(users);
            console.log("Deleted users" + users)
        })

        socket.on('leave_room', (users) => {
            users = JSON.parse(users)
            console.log("Leaving room from useEffect", users)
            setUsers(users)
        })
    }, [socket]);

    useEffect(() => {
        console.log("Selected number of current user changed :::: emitting it to server", selectedNumber)
        socket.emit('selected_number', JSON.stringify(
            {
                room,
                name,
                selectedNumber,
                users
            }
        ))
    }, [selectedNumber]);

    return (
        // <ThemeProvider theme={theme}>
        <Box sx={{marginTop: "25px", height: '85vh', overflowY: 'scroll'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Scrum Poker - Online Planning Poker tool for sprint planning</title>
                <meta name="description" content="Utilize our scrum poker/planning poker to estimate tasks and perform sprint planning efficiently" />
                <meta name="keywords" content="Scrum poker online, planning poker online, online tool for planning poker, online scrum tools, scrum planning tools online, online helper for sprint planning" />
                <meta name="author" content="Easy Online Tools" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://easyonlinetools.org/scrum-poker" />
            </Helmet>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h4" sx={{
                textAlign: "center",
                color: "#A1683A",
                fontFamily: "Comic Sans MS"
            }}>Scrum Poker</Typography>
            <Box sx={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2vh",
                marginBottom: "2vh",
            }}>
                <center>
                    {showRoom ?
                        <Box sx={{mt: "20px"}}>
                            <Typography variant="h5" sx={{color: "black", fontFamily: "Comic Sans MS"}}>Room
                                Name: <span style={{color: "#325a66"}}>{toCapitalCase(room)}</span></Typography>
                            <br/>
                            <Button variant={"contained"} color={"error"}
                                    sx={{"float": "right", right: 50, fontFamily: "Comic Sans MS"}}
                                    onClick={() => onClickLeaveRoom()}>Leave Room <ExitToAppIcon/></Button>
                        </Box> : joinOrCreateRoom === "join" ?
                            (
                                <Box>
                                    <TextField variant={"outlined"} label={"Room ID"} size={"small"} color={"primary"}
                                               sx={{mb: "10px", mr: "10px"}}
                                               onChange={onChangeRoom}/>
                                    <TextField variant={"outlined"} label={"Name"} size={"small"}
                                               onChange={onChangeName}/>
                                    <Button variant={"contained"} color={"primary"} sx={{
                                        ml: "10px"
                                    }} onClick={(e) => {
                                        onClickJoin()
                                    }}>
                                        Join Room
                                    </Button>
                                    <br/>
                                    <br/>
                                    <Link href={"#"} onClick={onClickCreateNewRoom}>Create a new room?</Link>
                                </Box>
                            ) :
                            (
                                <Box>
                                    <TextField variant={"outlined"} label={"Room ID"} size={"small"} color={"primary"}
                                               sx={{mb: "10px", mr: "10px"}}
                                               onChange={onChangeRoom}/>
                                    <TextField variant={"outlined"} label={"Name"} size={"small"}
                                               onChange={onChangeName}/>
                                    <Button variant={"contained"} color={"primary"} sx={{
                                        ml: "10px"
                                    }} onClick={(e) => {
                                        onClickCreateRoom()
                                    }}>
                                        Create
                                    </Button>
                                    <br/>
                                    <br/>
                                    <Link href={"#"} onClick={onClickJoinExistingRoom}>Join an existing room?</Link>
                                </Box>
                            )
                    }
                </center>
            </Box>
            {
                !showRoom ? <About/> : (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={5} xl={5}>
                            <Grid container spacing={2} sx={{
                                justifyContent: "flex-end",
                                display: "flex"
                            }}>
                                {fibonacci.map(number => {
                                    return (
                                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={number}>
                                            <NumberCard number={number} setSelectedNumber={setSelectedNumber}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container justifyContent={"flex-end"} sx={{marginTop: "20px"}} spacing={3}>
                                <Grid item sm={3}>
                                    <Button variant={"contained"} color={"primary"}
                                            sx={{':&hover': {'c': 'white'}, fontFamily: "Comic Sans MS"}}
                                            onClick={onClickShowEstimates}>Show
                                        Estimates <VisibilityOutlinedIcon/></Button>
                                </Grid>
                                <Grid item sm={3}>
                                    <Button variant={"contained"} color={"primary"}
                                            sx={{fontFamily: "Comic Sans MS"}} onClick={onClickHideEstimates}>Hide
                                        Estimates <VisibilityOffOutlinedIcon/></Button>
                                </Grid>
                                <Grid item sm={3}>
                                    <Button variant={"contained"} color={"primary"}
                                            sx={{fontFamily: "Comic Sans MS"}}
                                            onClick={onClickDeleteEstimates}>Delete
                                        Estimates <DeleteOutlineOutlinedIcon/></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={5} xl={5}>
                            <Grid container sx={{
                                justifyContent: {
                                    "xs": "center",
                                    "sm": "center",
                                    "md": "center",
                                    "lg": "flex-start",
                                    "xl": "flex-start"
                                },
                                alignItems: "center",
                                marginTop: "2vh",
                                marginBottom: "2vh",
                                marginLeft: {xs: "0px", sm: "0px", md: "0px", lg: "150px", xl: "250px"},
                            }}>
                                <Grid item>
                                    {users && users.map((user) => {
                                        const background = user.selectedNumber === min && showEstimates ? "#a0f09c" : user.selectedNumber === max && showEstimates ? "#de988a" : "#FFFFFF";
                                        return (
                                            <Item key={user.username} elevation={10} background={background}>
                                                {toCapitalCase(user.username)}: {user.selectedNumber ? (showEstimates ? user.selectedNumber :
                                                <VisibilityOffOutlinedIcon
                                                    sx={{width: "15px", height: "15px"}}/>) : '-'}
                                            </Item>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
        // </ThemeProvider>
    )
}

export default ScrumPoker;
