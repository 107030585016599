import "./App.css";
import io from "socket.io-client";
import {useEffect, useState} from "react";
import Spline from '@splinetool/react-spline';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import './common/components/styles.css'
import {Helmet} from "react-helmet";

const socket = io.connect("https://easy-online-tools-backend.herokuapp.com/");

function App() {
    //Room State
    const [room, setRoom] = useState("");
    const matches = useMediaQuery('(min-width:600px)');
    // Messages States
    const [message, setMessage] = useState("");
    const [messageReceived, setMessageReceived] = useState("");

    const navigate = useNavigate();

    const joinRoom = () => {
        if (room !== "") {
            socket.emit("join_room", room);
        }
    };

    const sendMessage = () => {
        socket.emit("send_message", {message, room});
    };

    useEffect(() => {
        socket.on("receive_message", (data) => {
            data = JSON.parse(data);
            setMessageReceived(data.message);
        });
    }, [socket]);
    return (
        <Box sx={{ maxWidth: '100vw' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Easy Online Tools - 100% free, simple and useful tools</title>
                <meta name="description" content="Our user-friendly suite of tools, including Scrum Poker, PDF Merger, and Plagiarism Checker, streamlines your tasks, saving you time and effort." />
                <meta name="keywords" content="Effortless online tools for streamlined productivity, Simplify your tasks with easy-to-use online tools, improve your productivity with our range of easy-to-use web-based solutions, Merge and split PDFs effortlessly with our easy online tools, Ensure content originality with powerful plagiarism detection tools, Effortlessly manage documents with our easy online PDF manipulation tools" />
                <meta name="author" content="Easy Online Tools" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://easyonlinetools.org/" />
            </Helmet>
            <Box sx={{height: '75vh'}}>
                <Typography variant={matches ? 'h2' : 'h3'} fontWeight={matches ? 'bold' : 'light'} sx={{
                    position: 'absolute',
                    right: '20%',
                    left: '20%',
                    top: '40%',
                    bottom: '40%',
                    color: '#825552'
                }}>Transform, Collaborate, and Create with Ease!</Typography>
                <Spline scene="https://prod.spline.design/xJWb6tiVe7VUjpDf/scene.splinecode"/>
            </Box>
            <Box sx={{height: '35vh', backgroundColor: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Typography variant="h4" sx={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px', paddingBottom: '20px'}}>Our Top Products</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <Typography variant="h5" sx={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { navigate('/scrum-poker') }}>Scrum Poker</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <Typography variant="h5" sx={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { navigate('/plagiarism-checker') }}>Plagiarism Checker</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <Typography variant="h5" sx={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { navigate('/merge-pdfs') }}>Merge PDF</Typography>
                        </Grid>
                    </Grid>
            </Box>
        </Box>
    );
}

export default App;
