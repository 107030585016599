import axios from "axios";
import FormData from "form-data";

export default async function mergePdfFiles (files, setMergedPdf) {

    let data = new FormData();
    files.forEach((file) => {
        data.append('pdfFiles', file);
    })

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/pdf/merge-pdf`, data)
        setMergedPdf(response.data.merged.data);
    }
    catch (error) {
        console.log("Error from API", error);
    }
}
