import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import EOTIcon from '../../assets/easyonlinetools-italics.png'
import {MenuList} from "@mui/material";

const pages = ['Home', 'Products', 'About'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const products = [
    {name: 'Scrum Poker', link: '/scrum-poker'},
    {name: 'Plagiarsm Checker', link: '/plagiarism-checker'},
    {name: 'Merge PDFs', link: '/merge-pdfs'}
]

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 128,
        maxWidth: '100vw',
    },
}));

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElProduct, setAnchorElProduct] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);
    const openProducts = Boolean(anchorElProduct);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setAnchorElProduct(null);
    };

    const onClickPage = (e, page) => {
        e.preventDefault();
        if (page == 'Products') {
            console.log('Products clicked', e.currentTarget)
            setAnchorElProduct(e.currentTarget);
            return
        }
        setAnchorElNav(null);
        setAnchorElProduct(null);
        navigate('/' + page.toLowerCase());
    }

    const handleCloseProductMenu = () => {
        setAnchorElNav(null);
        setAnchorElProduct(null);
    }

    const onClickProduct = (product) => {
        navigate(product.link);
        setAnchorElNav(null);
        setAnchorElProduct(null);
    }

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    return (
        <AppBar position="static" sx={{background: "#0A4D68", justifyContent: "center", maxWidth: '100vw'}}>
            <Container maxWidth="xl">
                <StyledToolbar disableGutters>
                    <img src={EOTIcon} alt="EOT" style={{width: "170px", height: "55px", marginRight: "75px", cursor: "pointer"}}
                         onClick={() => navigate("/")}/>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu} onClick={(e) => onClickPage(e, page)}>
                                    <Typography textAlign="center" sx={{ fontFamily: "Comic Sans MS" }}>{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={(e) => onClickPage(e, page)}
                                sx={{my: 2, color: 'white', display: 'block', margin: '10px', fontFamily: "Comic Sans MS"}}
                            >
                                {page}
                            </Button>
                        ))}
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorElProduct}
                            open={openProducts}
                            onClose={handleCloseProductMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {products.map((product) => (
                                <MenuItem key={product.name} onClick={() => onClickProduct(product) }>
                                    <Typography textAlign="center">{product.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/*Removing profile for time-being*/}

                    {/*<Box sx={{ flexGrow: 0 }}>*/}
                    {/*    <Tooltip title="Open settings">*/}
                    {/*        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
                    {/*            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />*/}
                    {/*        </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*    <Menu*/}
                    {/*        sx={{ mt: '45px' }}*/}
                    {/*        id="menu-appbar"*/}
                    {/*        anchorEl={anchorElUser}*/}
                    {/*        anchorOrigin={{*/}
                    {/*            vertical: 'top',*/}
                    {/*            horizontal: 'right',*/}
                    {/*        }}*/}
                    {/*        keepMounted*/}
                    {/*        transformOrigin={{*/}
                    {/*            vertical: 'top',*/}
                    {/*            horizontal: 'right',*/}
                    {/*        }}*/}
                    {/*        open={Boolean(anchorElUser)}*/}
                    {/*        onClose={handleCloseUserMenu}*/}
                    {/*    >*/}
                    {/*        {settings.map((setting) => (*/}
                    {/*            <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
                    {/*                <Typography textAlign="center">{setting}</Typography>*/}
                    {/*            </MenuItem>*/}
                    {/*        ))}*/}
                    {/*    </Menu>*/}
                    {/*</Box>*/}
                </StyledToolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
