import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const About = () => {
    return (
        <Box sx={{ minHeight: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant={'h2'} sx={{ color: '#825552' }}>About Us</Typography>
            <Typography variant={'h6'} sx={{ marginLeft: '50px', marginRight: '50px', marginTop: '50px' }}>Welcome to Easy Online Tools, the ultimate hub of innovative digital solutions designed to empower your online journey. We believe that technology should be accessible, intuitive, and most importantly, make your life easier. That's why we've curated a collection of cutting-edge tools that will revolutionize the way you work, study, and create in the digital realm.</Typography>
            <Typography variant={'h6'} sx={{ marginLeft: '50px', marginRight: '50px', marginTop: '50px' }}>We are a group of students from the University of Trier who are passionate about creating tools that make life easier for students and professionals alike. We are constantly working on new tools and improving existing ones. If you have any suggestions or feedback, please feel free to <a href="mailto:nandavikas@easyonlinetools.org">reach out to us</a></Typography>
        </Box>
    );
}
