import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import ReactGA from "react-ga4";
import DocumentMeta from "react-document-meta";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ScrumPoker from "./tools/scrum_poker";
import Navbar from "./common/components/navbar";
import Footer from "./common/components/footer";
import PlagiarismChecker from "./tools/plagiarism_checker";
import {About} from "./about";
import MergePdf from "./tools/pdf-tools/merge-pdf";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import PrivacyPolicy from "./privacy_policy";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Initialize GA-4
const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
ReactGA.initialize(GA4_MEASUREMENT_ID);

const theme = createTheme({
    palette: {
        primary: {
            main: "#088395",
            darker: "#05BFDB",
        },
    },
});

const Root = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<App/>}/>
                    <Route path="/home" element={<App/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/scrum-poker" element={<ScrumPoker/>}/>
                    <Route path="/plagiarism-checker" element={<PlagiarismChecker/>}/>
                    <Route path="/merge-pdfs" element={<MergePdf/>}/>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    {/*<Route path="*" element={<App/>}/>*/}
                </Routes>
                <Footer/>
            </BrowserRouter>
        </ThemeProvider>
    );
};

root.render(
    <React.StrictMode>
        <DevSupport
            ComponentPreviews={ComponentPreviews}
            useInitialHook={useInitial}
        >
            <Root/>
        </DevSupport>
    </React.StrictMode>
);

const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
};

reportWebVitals(SendAnalytics);
