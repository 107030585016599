import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import PolicyIcon from '@mui/icons-material/Policy';
import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 96,
    },
}));

export default function Footer() {
    const [value, setValue] = React.useState(0);
    const linkedInUrl = "https://www.linkedin.com/company/easy-online-tools";

    return (
        <Box>
            <StyledBottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{ backgroundColor: "#1c232b", width: "100%" }}
            >
                <BottomNavigationAction label="LinkedIn" style={{ color: "white" }} icon={<LinkedInIcon />} href={linkedInUrl}/>
                <BottomNavigationAction label="Email Us" style={{ color: "white" }} icon={<MailIcon />} href='mailto:nandavikas@easyonlinetools.org'/>
                <BottomNavigationAction label="Privacy Policy" style={{ color: "white" }} icon={<PolicyIcon />} href="/privacy-policy"/>
            </StyledBottomNavigation>
        </Box>
    );
}
