import axios from "axios";

export default async function checkPlagiarism(text, setResponse, setLoading) {
    console.log("Text to check plagiarism====>>>>>>", text);
    console.log("Backend URL", process.env.REACT_APP_BACKEND_URL)
    setLoading(true);
    let data = JSON.stringify({
        text
    });
    const API_URL = process.env.REACT_APP_BACKEND_URL + '/check-plagiarism';

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: API_URL,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            console.log("Response from API", JSON.stringify(response.data));
            setResponse({
                success: true,
                response: response.data
            });
            setLoading(false);
        })
        .catch((error) => {
            console.log("Error from API", error);
            setResponse({
                success: false,
                response: "An error occurred while checking plagiarism. Please try later."
            });
            setLoading(false);
        });
}
