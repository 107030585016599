import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Link} from "@mui/material";

export default function Matches({ source }) {

    console.log("Matches from matches.js", source)

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
                <ListItemText
                    primary={source.title}
                    secondary={
                        <React.Fragment>
                            {/*<Typography*/}
                            {/*    sx={{ display: 'inline' }}*/}
                            {/*    component="span"*/}
                            {/*    variant="body2"*/}
                            {/*    color="text.primary"*/}
                            {/*>*/}
                            {/*    {source.url}*/}
                            {/*</Typography>*/}
                            {/*{source.url}*/}
                            {/*{" — I'll be in your neighborhood doing errands this…"}*/}
                            <Link href={source.url} target="_blank" rel="noopener">View Source</Link>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider />
        </List>
    );
}
