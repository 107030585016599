import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const About = () => {
    return (
        <Box sx={{ margin: "50px" }}>
            <Typography variant={"h4"}>Overview</Typography>
            <Typography variant={"body1"}>
                Scrum poker, also known as planning poker, is a technique used in Agile software development to estimate the effort required for a particular task or user story. It is a fun and interactive way for the entire team to come together and collaboratively estimate the effort involved in completing a project.
                <br /><br />
                Scrum poker involves each team member selecting a card with a numerical value that represents the effort required for the task at hand. The values on the cards can vary, but commonly include values such as 0, 1, 2, 3, 5, 8, 13, 20, 40, and 100. The higher the number on the card, the greater the effort required for the task.
                <br /><br />
                Once all team members have selected a card, the cards are revealed simultaneously. If there is a wide variation in the estimates, the team can discuss the reasons for their differing opinions and refine the estimate until a consensus is reached.
                <br /><br />
                Scrum poker is an effective way to engage the entire team in the estimation process, as it ensures that everyone's opinion is taken into account. It also encourages open communication and can help identify potential roadblocks early on in the project.
                <br /><br />
                To implement scrum poker in your team, you can use physical cards or digital tools such as online voting systems or mobile apps. However, it is important to ensure that everyone on the team understands the rules and values associated with the different card values to avoid confusion or miscommunication during the estimation process.
                <br /><br />
                Overall, scrum poker is a valuable technique for Agile teams to estimate the effort required for a project, foster collaboration and communication among team members, and ultimately deliver high-quality software in a timely manner.
            </Typography>
        </Box>
    );
}

export default About;
