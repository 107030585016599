export const storeCookie = (name, value) => {
    localStorage.setItem(name, value);
}

export const getCookie = (name) => {
    return localStorage.getItem(name);
}

export const deleteCookie = (name) => {
    localStorage.removeItem(name);
}
