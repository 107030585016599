import React, {useRef, useState} from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import parse from 'html-react-parser';
import {styled} from '@mui/system';
import Paper from '@mui/material/Paper';
import checkPlagiarism from "./check_plagiarism";
import highlightPlagiarizedText from "./highlight_plagiarised_text";
import Matches from "./matches";
import {Helmet} from "react-helmet";

const PlagiarismChecker = () => {
    const [text, setText] = useState("");
    const [response, setResponse] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const plagiarismPercentage = response ? response.response.data.percentPlagiarism : undefined;
    const plagiarismColor = plagiarismPercentage < 10 ? "success" : (plagiarismPercentage < 40 ? "warning" : "error");
    const offsetsArray = response ? response.response.data.sources.map((source) => {
        console.log("Matches from map", source)
        return source.matches.map(match => [match.inputStart, match.inputEnd])
    }) : undefined;
    console.log("Response.response.data.sources =====>>>>>", response ? response.response.data.sources : "No response yet")
    console.log("offsetsArray =====>>>>>", offsetsArray)

    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#f6f8fa',
        100: '#eaeef2',
        200: '#d0d7de',
        300: '#afb8c1',
        400: '#8c959f',
        500: '#6e7781',
        600: '#57606a',
        700: '#424a53',
        800: '#32383f',
        900: '#24292f',
    };

    const StyledTextarea = styled(TextareaAutosize)(
        ({theme}) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
    );

    console.log('====================================');
    console.log("Response from Use State", response);
    return (
        <Box sx={{minHeight: '75vh'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Online Plagiarism checker - Free plagiarism Checker</title>
                <meta name="description" content="Free plagiarism checker tool that you can use online to easily detect plagiarism" />
                <meta name="keywords" content="check plagiarism online, free plagiarism checker tool, 100% free plagiarism detection, simple plagiarism checker, free tool to detect plagiarism" />
                <meta name="author" content="Easy Online Tools" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://easyonlinetools.org/plagiarism-checker" />
            </Helmet>
            <Typography variant="h4" sx={{
                textAlign: "center",
                color: "#A1683A",
                fontFamily: "Comic Sans MS",
                marginTop: "20px",
                marginBottom: "20px"
            }}>Plagiarism Checker</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '100px'
            }}>
                <StyledTextarea minRows={12} maxRows={20} variant={"outlined"} size={'lg'}
                                sx={{
                                    width: {xs: '90vw', sm: '90vw', md: '75vw', lg: '60vw', xl: '60vw'},
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                elevation={5}
                                value={text}
                                onChange={(e) => {
                                    setText(e.target.value);
                                    setResponse(undefined);
                                }}
                />
                <Button
                    variant="contained"
                    color={"primary"}
                    sx={{marginLeft: '20px', marginTop: '20px'}}
                    onClick={(e) => {
                        e.preventDefault();
                        console.log("Text from state", text);
                        checkPlagiarism(text, setResponse, setLoading)
                    }}
                    disabled={loading || text.trim().length === 0}
                >
                    Check Plagiarism
                </Button>
                {
                    (response !== null && loading) && <CircularProgress sx={{marginLeft: '20px', marginTop: '20px'}}/>
                }
                {
                    (response && !loading) &&
                    <Box sx={{
                        width: {xs: '90vw', sm: '90vw', md: '75vw', lg: '60vw', xl: '60vw'},
                        marginTop: '25px',
                        marginBottom: '25px'
                    }}>
                        <Typography variant="h6" color={plagiarismColor}
                                    sx={{textAlign: "center", fontFamily: "Comic Sans MS"}}>Plagiarism
                            Percentage: {plagiarismPercentage}%</Typography>
                        <LinearProgress variant={"determinate"} color={plagiarismColor} value={plagiarismPercentage}
                                        sx={{marginBottom: '25px'}}/>
                        <Typography variant="h6" color={'primary'}
                                    sx={{fontFamily: "Comic Sans MS", marginTop: '15px', marginBottom: '15px'}}>Plagiarized
                            Text</Typography>
                        <Paper elevation={5}
                               sx={{padding: '15px', marginTop: '15px', marginBottom: '15px', maxHeight: '50vh', overflowY: 'scroll'}}>
                            <Typography variant="body1"
                                        sx={{textAlign: "center", fontFamily: "Arial", marginTop: "25px"}}>
                                {parse(highlightPlagiarizedText(text, offsetsArray.flat()))}
                            </Typography>
                        </Paper>
                        <Typography variant="h6" color={'primary'} sx={{
                            fontFamily: "Comic Sans MS",
                            marginTop: '15px',
                            marginBottom: '15px'
                        }}>Matches</Typography>
                        {
                            response.response.data.sources.map((source) => (
                                <Matches source={source}/>
                            ))
                        }
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default PlagiarismChecker;
