import React from 'react';
import {Box, Typography} from "@mui/material";

export default function PrivacyPolicy() {
    return (
        <Box sx={{
            margin: '50px',
        }}>
            <Box sx={{
                margin: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="h3">Easyonlinetools Privacy Policy.</Typography>
                <br/>
                <Typography variant="h6" component="h6" gutterBottom>
                    Last updated: 06/07/2023
                    <br/>
                </Typography>
            </Box>
            <br/>
            <br/>

            <Typography variant="body1" component="p" gutterBottom>
                This Privacy Policy outlines how easyonlinetools (referred to as "Site," "we," "us," or "our") collects,
                uses, and discloses your personal information when you visit and use our services on easyonlinetools.org
                (referred to as the "Site") or interact with us in any other way (collectively referred to as the
                "Services"). By using or accessing any of the Services, you agree to the collection, use, and disclosure
                of your information as described in this Privacy Policy. If you do not agree to this Privacy Policy,
                please refrain from using or accessing the Services.
            </Typography>
            <br/>
            <br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Changes to This Privacy Policy
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                We may update this Privacy Policy periodically to reflect changes in our practices, operational
                requirements, or legal and regulatory obligations. The updated Privacy Policy will be posted on the
                Site, and we will indicate the "Last updated" date. Please review this Privacy Policy regularly to stay
                informed about how we collect, use, and disclose your personal information.
            </Typography>
            <br/>
            <br/>
            <Typography variant="h6" component="h6" gutterBottom>
                How We Collect and Use Your Personal Information
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                We collect and use personal information from various sources, depending on your interactions with the
                Site and use of our Services. This information is utilized to provide the Services, communicate with
                you, comply with legal obligations, and protect the rights of the Services, our users, and others. The
                following sections describe the types of personal information we collect and how we use it:
            </Typography>
            <br/>
            <br/>
            <Typography variant="h6" component="h6" gutterBottom>
                What Personal Information We Collect
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                The types of personal information we obtain about you depends on how you interact with our Site and
                use our Services. When we use the term "personal information", we are referring to information that
                identifies, relates to, describes or can be associated with you. The following sections describe the
                categories and specific types of personal information we collect.
            </Typography>
            <br/>
            <br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Information We Collect Directly from You
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                - Basic contact details including your name, address, phone number, email.<br/>
                - Order information including your name, billing address, shipping address, payment confirmation,
                email address, phone number.<br/>
                - Account information including your username, password, security questions.<br/>
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Information We Collect through Cookies
            </Typography>
            <br/><br/>
            <Typography variant="body1" component="p" gutterBottom>
                We also automatically collect certain information about your interaction with the Services ("Usage
                Data"). To do this, we may use cookies, pixels and similar technologies ("Cookies"). Usage Data may
                include information about how you access and use our Site and your account, including device
                information, browser information, information about your network connection, your IP address and
                other information regarding your interaction with the Services.
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Information We Obtain from Third Parties
            </Typography>
            <br/><br/>
            <Typography variant="body1" component="p" gutterBottom>
                We may receive information about you from third parties, including service providers, vendors, and
                advertising partners. These sources may collect information on our behalf, such as payment details to
                process transactions or data related to your interactions with the Services. Any information obtained
                from third parties will be treated in accordance with this Privacy Policy.
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                How We Use Your Personal Information
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                In certain circumstances, we may disclose your personal information to third parties for legitimate
                purposes as outlined in this Privacy Policy. These may include vendors, service providers, business
                partners, and affiliates who assist us in providing the Services and delivering a personalized
                experience. We may also disclose information to comply with legal obligations, respond to legal
                requests, enforce terms of service, protect the rights and safety of our users and others, or in
                connection with business transactions.
            </Typography>
            <br/><br/>

            <Typography variant="h6" component="h6" gutterBottom>
                Security and Retention of Your Information
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                We employ industry-standard security measures to protect your personal information from unauthorized
                access, disclosure, or alteration. However, no method of transmission over the internet or electronic
                storage is entirely secure, and we cannot guarantee absolute security.
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Third-Party Websites and Links:
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                The Site may contain links to third-party websites or services that are not operated by us. We are not
                responsible for the privacy practices or content of such third-party sites. We encourage you to review
                the privacy policies of these third-party websites before interacting with them.
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Your Rights and Choices
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                You have the right to access, update, and delete your personal information, subject to applicable laws.
                You can manage your cookie preferences and opt-out of certain data collection by adjusting your browser
                settings. However, please note that blocking or removing cookies may affect the functionality and
                availability of certain features on the Site.
            </Typography>
            <br/><br/>
            <Typography variant="h6" component="h6" gutterBottom>
                Contact
            </Typography>
            <br/>
            <br/>
            <Typography variant="body1" component="p" gutterBottom>
                Should you have any questions about our privacy practices or this Privacy Policy, or if you would
                like to exercise any of the rights available to you, please email us at nandavikas858@gmail.com or
                contact us at
                Whitefield, Bengaluru, KA, 560066, India.
            </Typography>
            {/*</Typography>*/}
        </Box>
    )
}
