import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import {FileUploader} from "react-drag-drop-files";
import mergePdfFiles from "./merge_pdfs";
import {Helmet} from "react-helmet";

const fileTypes = ["PDF"];

const MergePdf = () => {
    const [files, setFiles] = useState([{name: "File-1"}, {name: "File-2"}]);
    const [mergedPdf, setMergedPdf] = useState(undefined);
    const [mergeDisabled, setMergeDisabled] = useState(true);

    const onClickMerge = async (e) => {
        setMergeDisabled(true);
        try {
            const mergePDFs = await mergePdfFiles(files, setMergedPdf);
        }
        catch (error) {
            console.log("Error from API", error);
        }
        setMergeDisabled(false);
    };

    const onClickAddMore = (e) => {
        if (files.length <= 4) {
            setFiles([...files, {name: `File-${files.length + 1}`}]);
        }
    };

    const onClickDownload = async (e) => {
        const uint8Array = new Uint8Array(mergedPdf);
        const responseBlob = new Blob([uint8Array], {type: "application/pdf"});
        const downloadUrl = URL.createObjectURL(responseBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "merged.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChange = (file, index) => {
        const newFiles = [...files];
        newFiles[index] = file[0];
        const fileteredFiles = newFiles.filter((file) => file.size !== undefined);
        if (fileteredFiles.length > 1) {
            setMergeDisabled(false);
        }
        setFiles(newFiles);
    };

    const onClickDelete = (index) => {
        const newFiles = [...files];
        if (newFiles.length > 2) {
            setMergeDisabled(true);
            newFiles.splice(index, 1);
            setFiles(newFiles);
        }
        newFiles[index] = {name: `File-${newFiles.length}`};
        setMergeDisabled(false);
        setFiles(newFiles);
    }

    return (
        <Box
            sx={{
                minHeight: "75vh",
            }}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Free tool to merge multiple PDF files online</title>
                <meta
                    name="description"
                    content="Our user-friendly suite of tools, including Scrum Poker, PDF Merger, and Plagiarism Checker, streamlines your tasks, saving you time and effort."
                />
                <meta
                    name="keywords"
                    content="Merge multiple pdfs online, merge pdfs for free, add and combine pdfs online, online tool to join pdfs for free, 100% free pdf merger"
                />
                <meta name="author" content="Easy Online Tools"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://easyonlinetools.org/merge-pdfs"/>
            </Helmet>
            <Typography
                variant="h4"
                sx={{
                    textAlign: "center",
                    color: "#A1683A",
                    fontFamily: "Comic Sans MS",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                Merge PDFs
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Alert
                    severity="info"
                    sx={{
                        width: {
                            xs: "90vw",
                            sm: "90vw",
                            md: "75vw",
                            lg: "65vw",
                            xl: "50vw",
                        },
                        marginBottom: "50px",
                    }}
                >
                    You can add upto 5 PDF files
                </Alert>
                {files.map((file, index) => {
                    console.log("File", file.label);
                    return (
                        <Box sx={{marginBottom: "15px"}}>
                            <FileUploader
                                handleChange={(file) => handleChange(file, index)}
                                name={file.name}
                                label={file.label}
                                types={fileTypes}
                                multiple={true}
                            />
                            {file.size > 0 && <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant="body1"
                                            sx={{fontFamily: 'Helvetica', fontSize: '14px'}}>{file.name}</Typography>
                                <DeleteIcon color={"error"} onClick={() => {
                                    onClickDelete(index)
                                }}/>
                            </Stack>}
                        </Box>
                    );
                })}
                <Button
                    variant={"outlined"}
                    sx={{marginTop: "25px"}}
                    onClick={onClickAddMore}
                    disabled={files.length >= 5}
                >
                    <AddIcon/>
                    Add More
                </Button>
                <Button
                    variant={"contained"}
                    sx={{marginTop: "25px", marginBottom: '25px', width: "350px"}}
                    disabled={mergeDisabled}
                    onClick={onClickMerge}
                >
                    Merge
                </Button>
                {Boolean(mergedPdf) && (
                    <Button
                        variant={"contained"}
                        sx={{marginBottom: "25px", width: "350px"}}
                        disabled={mergeDisabled}
                        onClick={onClickDownload}
                    >
                        Download
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default MergePdf;
