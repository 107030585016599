import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function NumberCard({number, setSelectedNumber}) {
    return (
        <Card sx={{
            width: "150px",
            height: "75px",
            color: "#E8998D",
            margin: "20px",
            "&:hover": {
                backgroundColor: "#EED2CC",
                color: "#ffffff",
                cursor: "pointer",
            }
        }}
              onClick={() => setSelectedNumber(number)}
        >
            <CardContent sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}>
                <Typography variant="h4" component="div">
                    {number}
                </Typography>
            </CardContent>
        </Card>
    );
}
